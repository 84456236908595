<template>
  <div
    class="carousel-container"
    :class="mode === 'scroll' && 'carousel-container--mobile'"
  >
    <!-- @TODO: change for responsive styling  -->
    <button
      v-if="mode === 'button' && currentPage !== 1"
      class="carousel-btn carousel-left"
      :style="{ top: chevronTopPosition }"
      @click="() => emits('prev')"
    >
      <ChevronLeftIcon
        width="18"
        height="18"
      />
    </button>
    <slot></slot>
    <button
      v-if="mode === 'button' && currentPage !== totalPage"
      class="carousel-btn carousel-right"
      :style="customStyle"
      @click="() => emits('next')"
    >
      <ChevronRightIcon
        width="18"
        height="18"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
  import ChevronLeftIcon from '~/assets/icons/chevron-left.svg'
  import ChevronRightIcon from '~/assets/icons/chevron-right.svg'

  interface ICarouselProps {
    mobileScrollMode?: 'button' | 'scroll'
    totalPage: number
    currentPage: number
    chevronTopPosition?: number
  }

  interface ICarouselEmits {
    (e: 'prev'): void
    (e: 'next'): void
  }

  const props = defineProps<ICarouselProps>()
  const emits = defineEmits<ICarouselEmits>()

  const mode = ref('button')

  const customStyle = computed(() => {
    if (props.chevronTopPosition) {
      return {
        top: `${props.chevronTopPosition}px`,
      }
    }

    return {}
  })

  onMounted(() => {
    if (window.screen.width <= 1024) {
      mode.value = props.mobileScrollMode || 'scroll'
    }
  })
</script>

<style lang="scss">
  .carousel-container > a {
    flex-basis: 25%;
  }
</style>

<style scoped lang="scss">
  .carousel-container {
    display: flex;
    position: relative;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;

    .carousel-btn {
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: center;
      padding: 8px;
      border: none;
      border-radius: 50%;
      background: $base100;
      box-shadow: 0px 18px 88px -4px rgba(24, 39, 75, 0.3),
        0px 8px 28px -6px rgba(24, 39, 75, 0.3);
      position: absolute;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }
    }

    .carousel-left {
      left: -15px;
    }

    .carousel-right {
      right: -15px;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .carousel-container--mobile {
      overflow-x: scroll;
      padding: 0 24px;
    }
  }
</style>
